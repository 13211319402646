import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from "../components/Header";

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const UserRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRequest, setSelectedRequest] = useState(null); // Estado para la fila seleccionada
  const [modalVisible, setModalVisible] = useState(false); // Estado para la visibilidad del modal
  const [searchTerm, setSearchTerm] = useState(''); // Estado para el término de búsqueda
  const rowsPerPage = 5;

  const fetchRequests = async () => {
    //setLoading(true);
    try {
      const response = await Axios.post(
          "sca/obtenerSolicitud",
          {},
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
            }
          }
      );
      if (response.data.success) {
        setRequests(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      //setLoading(false);
    }
  };

  const setPending = async (request) => {
    //setLoading(true);
    try {
      const response = await Axios.post(
          "sca/updateSolicitud",
          {
            "idsolicitud": [request.idsolicitud],
            "idestadosolicitud": 2,
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
            }
          }
      );
      if (response.data.success) {
        fetchRequests();
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      //setLoading(false);
    }
  };

  const setCompleted = async (request) => {
    //setLoading(true);
    try {
      const response = await Axios.post(
          "sca/updateSolicitud",
          {
            "idsolicitud": [request.idsolicitud],
            "idestadosolicitud": 3,
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
            }
          }
      );
      if (response.data.success) {
        fetchRequests();
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      //setLoading(false);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  const getRequestType = (id) => {
    switch (id) {
      case '1':
        return 'Refund';
      case '2':
        return 'Feature Request';
      default:
        return 'Other';
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case '1':
        return 'bg-yellow-500 text-black';
      case '2':
        return 'bg-gray-500 text-white';
      case '3':
        return 'bg-green-500 text-black';
      default:
        return '';
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case '1':
        return 'In Review';
      case '2':
        return 'Pending';
      case '3':
        return 'Completed';
      default:
        return 'Unknown';
    }
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  // Filtrar las solicitudes según el término de búsqueda
  const filteredRequests = requests.filter(request => {
    return (
        request.tiposolicitud.toLowerCase().includes(searchTerm.toLowerCase()) ||
        request.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        request.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        request.phonenumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
        request.creationdate.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (request.modificationdate && request.modificationdate.toLowerCase().includes(searchTerm.toLowerCase())) ||
        getStatusText(request.idestadosolicitud).toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const currentRows = filteredRequests.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredRequests.length / rowsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowClick = (request) => {
    setSelectedRequest(request);
  };

  const showDetails = (request) => {
    setSelectedRequest(request);
    setModalVisible(true); // Mostrar el modal al hacer clic en una fila
  };

  const closeModal = () => {
    setModalVisible(false); // Función para cerrar el modal
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const highlightMatch = (text) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    return text.split(regex).map((part, index) =>
        part.toLowerCase() === searchTerm.toLowerCase() ? (
            <span key={index} className="bg-yellow-700">{part}</span>
        ) : (
            part
        )
    );
  };

  return (
      <div className="bg-gray-900 text-white min-h-screen p-4 md:p-8">
        <header className="flex items-center justify-between mb-8">
          <div className="flex items-center space-x-4"></div>
        </header>
        <main>
          <h2 className="text-2xl md:text-3xl font-bold mb-6">All requests</h2>
          <div className="relative mb-8">
            <input
                type="text"
                placeholder="Search user requests"
                className="w-full p-4 rounded-md bg-gray-800 text-white placeholder-gray-500 focus:outline-none"
                value={searchTerm}
                onChange={handleSearchChange}
            />
            <i className="fas fa-search absolute top-1/2 transform -translate-y-1/2 right-4 text-gray-500"></i>
          </div>
          <div className="flex justify-end mb-4">
            <button
                onClick={fetchRequests}
                className="px-4 py-2 bg-gray-800 text-white rounded-md"
            >
              Update
            </button>
          </div>
          {loading ? (
              <div className="flex justify-center items-center min-h-screen">
                <div className="loader"></div>
              </div>
          ) : (
              <div className="overflow-x-auto">
                <table className="w-full text-left">
                  <thead className="bg-gray-800 sticky top-0">
                  <tr>
                    <th className="p-2 md:p-4">Type of Request</th>
                    <th className="p-2 md:p-4">Name and Surname or Company</th>
                    <th className="p-2 md:p-4">Email</th>
                    <th className="p-2 md:p-4">Phone Number</th>
                    <th className="p-2 md:p-4">Date created</th>
                    <th className="p-2 md:p-4">Date modified</th>
                    <th className="p-2 md:p-4">Status</th>
                    <th className="p-2 md:p-4"></th>
                    <th className="p-2 md:p-4"></th>
                    <th className="p-2 md:p-4"></th>
                  </tr>
                  </thead>
                  <tbody>
                  {currentRows.map((request) => (
                      <tr
                          key={request.idsolicitud}
                          className="border-b border-gray-700 cursor-pointer"
                          onClick={() => handleRowClick(request)} // Manejar clic en la fila
                      >
                        <td className="p-2 md:p-4">{highlightMatch(request.tiposolicitud)}</td>
                        <td className="p-2 md:p-4">{highlightMatch(request.fullname)}</td>
                        <td className="p-2 md:p-4">{highlightMatch(request.email)}</td>
                        <td className="p-2 md:p-4">{highlightMatch(request.phonenumber)}</td>
                        <td className="p-2 md:p-4">{highlightMatch(request.creationdate)}</td>
                        <td className="p-2 md:p-4">{highlightMatch(request.modificationdate || 'N/A')}</td>
                        <td className="p-2 md:p-4">
                      <span className={`px-4 py-1 rounded-full text-sm ${getStatusClass(request.idestadosolicitud)}`}>
                        {getStatusText(request.idestadosolicitud)}
                      </span>
                        </td>
                        <td className="p-2 md:p-4">
                          <button
                              onClick={() => setPending(request)}
                              className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-full h-10 px-4 bg-yellow-500 text-[#181511] text-sm font-bold leading-normal tracking-[0.015em]"
                          >
                            <span className="truncate">Pending</span>
                          </button>
                        </td>
                        <td className="p-2 md:p-4">
                          <button
                              onClick={() => setCompleted(request)}
                              className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-full h-10 px-4 bg-green-500 text-[#181511] text-sm font-bold leading-normal tracking-[0.015em]"
                          >
                            <span className="truncate">Completed</span>
                          </button>
                        </td>
                        <td className="p-2 md:p-4">
                          <button
                              onClick={() => showDetails(request)}
                              className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-full h-10 px-4 bg-green-500 text-[#181511] text-sm font-bold leading-normal tracking-[0.015em]"
                          >
                            <span className="truncate">Show</span>
                          </button>
                        </td>
                      </tr>
                  ))}
                  </tbody>
                </table>
                <div className="flex justify-between mt-4">
                  <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className="px-4 py-2 bg-gray-800 text-white rounded-md"
                  >
                    Previous
                  </button>
                  <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className="px-4 py-2 bg-gray-800 text-white rounded-md"
                  >
                    Next
                  </button>
                </div>
              </div>
          )}
        </main>

        {/* Modal para mostrar detalles */}
        {modalVisible && selectedRequest && (
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
              <div className="relative w-auto max-w-4xl mx-auto my-6">
                {/* Contenido del modal */}
                <div className="grid grid-cols-2 gap-4 border-0 rounded-lg shadow-lg relative bg-white outline-none focus:outline-none">
                  {/* Primera columna */}
                  <div className="p-6">
                    {/* Encabezado del modal */}
                    <div className="flex items-start justify-between border-b border-solid border-gray-300 rounded-t mb-4">
                      <h3 className="text-gray-700 font-semibold">
                        Details for Request
                      </h3>
                    </div>
                    {/* Cuerpo del modal - primera columna */}
                    <div className="mb-4 text-gray-700">
                      <label className="block text-sm font-bold mb-2">
                        Type of Request:
                      </label>
                      <p>{selectedRequest.tiposolicitud}</p>
                    </div>
                    <div className="mb-4 text-gray-700">
                      <label className="block text-sm font-bold mb-2">
                        Name and Surname or Company:
                      </label>
                      <p>{selectedRequest.fullname}</p>
                    </div>
                    <div className="mb-4 text-gray-700">
                      <label className="block text-sm font-bold mb-2">
                        Email:
                      </label>
                      <p>{selectedRequest.email}</p>
                    </div>
                    <div className="mb-4 text-gray-700">
                      <label className="block text-sm font-bold mb-2">
                        Phone Number:
                      </label>
                      <p>{selectedRequest.phonenumber}</p>
                    </div>
                  </div>
                  {/* Segunda columna */}
                  <div className="p-6">
                    <div className="mb-4 text-gray-700">
                      <label className="block text-sm font-bold mb-2">
                        Description:
                      </label>
                      <p>{selectedRequest.description}</p>
                    </div>
                    <div className="mb-4 text-gray-700">
                      <label className="block text-sm font-bold mb-2">
                        Date created:
                      </label>
                      <p>{selectedRequest.creationdate}</p>
                    </div>
                    <div className="mb-4 text-gray-700">
                      <label className="block text-sm font-bold mb-2">
                        Date modified:
                      </label>
                      <p>{selectedRequest.modificationdate || 'N/A'}</p>
                    </div>
                    <div className="mb-4 text-gray-700">
                      <label className="block text-sm font-bold mb-2">
                        Status:
                      </label>
                      <span className={`px-4 py-1 rounded-full text-sm ${getStatusClass(selectedRequest.idestadosolicitud)}`}>
                        {getStatusText(selectedRequest.idestadosolicitud)}
                      </span>
                    </div>
                    {/* Pie del modal */}
                    <div className="flex items-center justify-end p-6 ">
                      <button
                          className="px-6 py-2 bg-gray-800 text-white rounded-md"
                          onClick={closeModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        )}
      </div>
  );
};
export default UserRequests;