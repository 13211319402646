import AppRouter from './routes/AppRoutes.js';
import axios from "axios";
import React, {useEffect, useState} from 'react';

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const App = () => {
  // const [token, setToken] = useState(null);
  // const [headers, setHeaders] = useState(null);

  useEffect(() => {

    // if(sessionStorage.getItem('idioma'))
    //   setIdioma(sessionStorage.getItem('idioma'));

    async function getToken() {
      Axios.post('/login',{
        "username": process.env.REACT_APP_USER,
        "password": process.env.REACT_APP_PASS
      }).then(response =>{
        // let aux = response.data.token;
        sessionStorage.setItem('token', response.data.token);
        // setToken(aux);
        let headerAux = {
          'accept': '*/*',
          'Authorization': 'Bearer ' + response.data.token,
          'Content-Type': 'application/json',
        };
        // setHeaders(headerAux);
      }).catch(error => {
        // setToken(null);
        // setHeaders(null);
        sessionStorage.removeItem('token');
      });
    }
    getToken();
  }, []);

  return <AppRouter />;
};

export default App;
