import React from 'react';
import Slider from 'react-slick';
import { Helmet } from 'react-helmet';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../custom.css";

const Hero = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: <button className="slick-prev custom-prev">&lt;</button>,
    nextArrow: <button className="slick-next custom-next">&gt;</button>,
  };

  return (
    <div 
      className="flex min-h-[480px] flex-col items-center justify-end p-4 gap-6 bg-cover bg-center bg-no-repeat mt-40" 
      style={{ 
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%),' 
      }}
    >
      <Helmet>          
          <meta name="Companies" content="Name of Sailors Crewing Agency." />
          <meta property="og:title" content="English - Sailors Crewing Agency" />
          <meta property="og:spanish" content="Spanish - AGENCIA DE CONTRATACIÓN DE MARINOS" />
      </Helmet>
      <h1 className="text-primary text-3xl max-md:w-40 font-black text-center drop-shadow-custom-1 shadow-black">
        SAILORS CREWING AGENCY
      </h1>
      <h3 className="text-primary text-base md:text-lg font-semibold text-center drop-shadow-custom-2 shadow-black">
        AGENCIA DE CONTRATACIÓN DE MARINOS
      </h3>

      <div className="w-full max-w-screen-lg mt-6 max-md:w-64">
        <Slider {...settings}>
          <div>
            <img src="/img/Designer1.jpeg" alt="Image 1" className="w-full h-[200px] md:h-[300px] object-cover" />
          </div>
          <div>
            <img src="/img/Designer2.jpeg" alt="Image 2" className="w-full h-[200px] md:h-[300px] object-cover" />
          </div>
          <div>
            <img src="/img/Designer3.jpeg" alt="Image 3" className="w-full h-[200px] md:h-[300px] object-cover" />
          </div>
          <div>
            <img src="/img/Designer4.jpeg" alt="Image 4" className="w-full h-[200px] md:h-[300px] object-cover" />
          </div>
          <div>
            <img src="/img/carga1.png" alt="Image 5" className="w-full h-[200px] md:h-[300px] object-cover" />
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default Hero;
