import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PoliticaPrivacidad = () => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className="flex min-h-[480px] flex-col items-center justify-normal p-4 gap-6 bg-cover bg-center bg-no-repeat mt-40"

    >
      <h1 className="text-primary text-4xl font-black text-center drop-shadow-custom-1 shadow-black">
        {t('content.polity')}
      </h1>
      <div className="max-w-xl mx-auto rounded-lg shadow-lg border border-[#f4f3f0] bg-white p-3 text-justify    ">
        
      {/* <h1>{t('privacy_policy.title')}</h1> */}
      
      <h2><strong>{t('privacy_policy.objective_title')}</strong></h2>
      <br></br>
      <p>{t('privacy_policy.objective_text')}</p>
      <br></br>
      <h3><strong>{t('privacy_policy.key_aspects_title')}</strong></h3>
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        <li dangerouslySetInnerHTML={{ __html: t('privacy_policy.transparency') }}/>
        <li dangerouslySetInnerHTML={{ __html: t('privacy_policy.legal_compliance') }}/>
        <li dangerouslySetInnerHTML={{ __html: t('privacy_policy.data_protection') }}/>
        <li dangerouslySetInnerHTML={{ __html: t('privacy_policy.user_rights') }}/>
      </ul>
      <br></br>
      <p><strong>{t('privacy_policy.summary')}</strong></p>
      <br></br>
      <h2><strong>{t('privacy_policy.controller_info_title')}</strong></h2>
      <ul>
        <li dangerouslySetInnerHTML={{ __html: t('privacy_policy.name') }}/>
        <li dangerouslySetInnerHTML={{ __html: t('privacy_policy.email') }}/>
        <li dangerouslySetInnerHTML={{ __html: t('privacy_policy.phone') }}/>
        <li dangerouslySetInnerHTML={{ __html: t('privacy_policy.address') }}/>
        <li dangerouslySetInnerHTML={{ __html: t('privacy_policy.website') }}/>
      </ul>
      <br></br>
      <h3><strong>{t('privacy_policy.additional_info')}</strong></h3>
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        {t('privacy_policy.agency_description', { returnObjects: true }).map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <br></br>
      <h2><strong>{t('privacy_policy.data_processing_purposes.title')}</strong></h2>
      
      <h3><strong>{t('privacy_policy.data_processing_purposes.users_and_web_surfers.title')}</strong></h3>
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        {t('privacy_policy.data_processing_purposes.users_and_web_surfers.purposes', { returnObjects: true }).map((item, index) => (
          <li key={index} dangerouslySetInnerHTML={{ __html: item }}/>
        ))}
      </ul>
      <br></br>
      <h3><strong>{t('privacy_policy.data_processing_purposes.customers.title')}</strong></h3>
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        {t('privacy_policy.data_processing_purposes.customers.purposes', { returnObjects: true }).map((item, index) => (
          <li key={index} dangerouslySetInnerHTML={{ __html: item }}/>
        ))}
      </ul>
      <br></br>
      <h3><strong>{t('privacy_policy.data_processing_purposes.important.title')}</strong></h3>
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        {t('privacy_policy.data_processing_purposes.important.points', { returnObjects: true }).map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <br></br>
      <h3><strong>{t('privacy_policy.data_retention.title')}</strong></h3>
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        {t('privacy_policy.data_retention.points', { returnObjects: true }).map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <br></br>
      <h3><strong>{t('privacy_policy.specific_conservation_periods.title')}</strong></h3>
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        {t('privacy_policy.specific_conservation_periods.points', { returnObjects: true }).map((item, index) => (
          <li key={index} dangerouslySetInnerHTML={{ __html: item }}/>
        ))}
      </ul>
      <br></br>        
      <h3><strong>{t('privacy_policy.automated_decisions_and_profiling.title')}</strong></h3>
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        {t('privacy_policy.automated_decisions_and_profiling.points', { returnObjects: true }).map((item, index) => (
          <li key={index} dangerouslySetInnerHTML={{ __html: item }}/>
        ))}
      </ul>
      <br></br>
      <h3><strong>{t('privacy_policy.recipients_of_your_data.title')}</strong></h3>
      <p>{t('privacy_policy.recipients_of_your_data.intro')}</p>
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        {t('privacy_policy.recipients_of_your_data.points', { returnObjects: true }).map((item, index) => (
          <li key={index} dangerouslySetInnerHTML={{ __html: item }}/>
        ))}
      </ul>
      <br></br>
      <h3><strong>{t('privacy_policy.international_data_transfers.title')}</strong></h3>
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        {t('privacy_policy.international_data_transfers.points', { returnObjects: true }).map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <br></br>
      <h3><strong>{t('privacy_policy.your_rights_in_relation_to_your_data.title')}</strong></h3>
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        {t('privacy_policy.your_rights_in_relation_to_your_data.points', { returnObjects: true }).map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
        <br></br>
      <h3><strong>{t('privacy_policy.changes_to_this_policy.title')}</strong></h3>
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        {t('privacy_policy.changes_to_this_policy.points', { returnObjects: true }).map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <br></br>
      <h3><strong>{t('privacy_policy.user_responsibility_for_the_veracity_of_their_data.title')}</strong></h3>
      <p>{t('privacy_policy.user_responsibility_for_the_veracity_of_their_data.text')}</p>
    
      </div>
    </div>
  );
};

export default PoliticaPrivacidad;
