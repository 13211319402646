import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AvisoLegal = () => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className="flex min-h-[480px] flex-col items-center justify-normal p-4 gap-6 bg-cover bg-center bg-no-repeat mt-40"

    >
      <h1 className="text-primary text-4xl font-black text-center drop-shadow-custom-1 shadow-black">
        {t('content.aviso')}
      </h1>
      <div className="max-w-xl mx-auto rounded-lg shadow-lg border border-[#f4f3f0] bg-white p-3 text-justify">
      <div>
      <p>{t('compliance_information.intro')}</p>
      <br></br>
      <section>
        <h2><strong>{t('compliance_information.website_owner.title')}</strong></h2>
        <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
          {t('compliance_information.website_owner.details', { returnObjects: true }).map((detail, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: detail }}/>
          ))}
        </ul>
        <br></br>
      </section>
      <section>
        <h2><strong>{t('compliance_information.terms_of_use.title')}</strong></h2>
        <p>{t('compliance_information.terms_of_use.text')}</p>
      </section>
      <br></br>
      <section>
        <h2><strong>{t('compliance_information.access_and_registration.title')}</strong></h2>
        <p>{t('compliance_information.access_and_registration.text')}</p>
      </section>
      <br></br>
      <section>
        <h2><strong>{t('compliance_information.use_of_website.title')}</strong></h2>
        <p>{t('compliance_information.use_of_website.text')}</p>
      </section>
      <br></br>
      <section>
        <h2><strong>{t('compliance_information.contents.title')}</strong></h2>
        <p>{t('compliance_information.contents.text')}</p>
      </section>
      <br></br>
      <section>
        <h2><strong>{t('compliance_information.exclusion_of_liability.title')}</strong></h2>
        <p>{t('compliance_information.exclusion_of_liability.text')}</p>
      </section>
      <br></br>
      <section>
        <h2><strong>{t('compliance_information.links.title')}</strong></h2>
        <p>{t('compliance_information.links.text')}</p>
      </section>
      <br></br>
      <section>
        <h2><strong>{t('compliance_information.intellectual_and_industrial_property.title')}</strong></h2>
        <p>{t('compliance_information.intellectual_and_industrial_property.text')}</p>
      </section>
      <br></br>
      <p><strong>{t('ProteccionDeDatos')}</strong></p>
      <p>{t('ParaConocerComoSeTratanDatos')}</p>
      <br></br>
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        <li dangerouslySetInnerHTML={{ __html: t('ResponsableDelTratamiento') }}></li>
        <li dangerouslySetInnerHTML={{ __html: t('FinalidadesDelTratamiento') }}></li>
        <li dangerouslySetInnerHTML={{ __html: t('LegitimacionDelTratamiento') }}></li>
        <li dangerouslySetInnerHTML={{ __html: t('DerechosDelUsuario') }}></li>
        <li dangerouslySetInnerHTML={{ __html: t('ConservacionDeLosDatos') }}></li>
      </ul>
      <br></br>
      <p><strong>{t('ContenidosPropiedadIntelectual')}</strong></p>
      <p>{t('SailorsEsTitular')}</p>
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
      <li dangerouslySetInnerHTML={{ __html: t('Contenidos') }}></li>
      <li dangerouslySetInnerHTML={{ __html: t('CodigoFuente') }}></li>
      <li dangerouslySetInnerHTML={{ __html: t('DisenoEstructura') }}></li>
      </ul>
      <br></br>
      <p><strong>{t('QuedanProhibidas')}</strong></p>
      <br></br>
      <p><strong>{t('ElUsuarioSeCompromete')}</strong></p>
      <br></br>
      <p><strong>{t('ExclusionGarantiasResponsabilidad')}</strong></p>
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        <li dangerouslySetInnerHTML={{ __html: t('FuncionamientoCorrecto') }}></li>
        <li dangerouslySetInnerHTML={{ __html: t('UtilidadAdecuacion') }}></li>
        <li dangerouslySetInnerHTML={{ __html: t('PresenciaVirus') }}></li>
        <li dangerouslySetInnerHTML={{ __html: t('DaniosPerjuicios') }}></li>
      </ul>
      <br></br>
      <p><strong>{t('UsuarioUtilizaSitio')}</strong></p>
      <br></br>
      <p><strong>{t('ModificacionAvisoLegal')}</strong></p>
      <br></br>
      <p>{t('SailorsSeReserva')}</p>
      <br></br>
      <p><strong>{t('AvisoLegalDuracion')}</strong></p>
      <br></br>
      <p><strong>{t('JurisdiccionLegislacion')}</strong></p>
      <p>{t('RelacionesSailorsUsuario')}</p>
    </div>
    <br></br>
    <section>
        <h2><strong>{t('legal_notice.links.title')}</strong></h2>
        
        <p><strong>{t('legal_notice.links.intro')}</strong></p>
        <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
          {t('legal_notice.links.details', { returnObjects: true }).map((detail, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: detail }}/>
          ))}
        </ul>
      </section>
      <br></br>
      <section>
        <h2><strong>{t('legal_notice.exclusion_rights.title')}</strong></h2>
        <p><strong>{t('legal_notice.exclusion_rights.intro')}</strong></p>
        <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
          {t('legal_notice.exclusion_rights.details', { returnObjects: true }).map((detail, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: detail }}/>
          ))}
        </ul>
      </section>
      <br></br>
      <section>
        <h2><strong>{t('legal_notice.generalities.title')}</strong></h2>
        <p>{t('legal_notice.generalities.text')}</p>
      </section>
      <br></br>
      <section>
        <h2><strong>{t('legal_notice.applicable_law.title')}</strong></h2>
        <p>{t('legal_notice.applicable_law.text')}</p>
      </section>
      <br></br>
      <section>
        <h2><strong>{t('legal_notice.minors.title')}</strong></h2>
        <p>{t('legal_notice.minors.text')}</p>
      </section>
      <br></br>
      <section>
        <h2><strong>{t('legal_notice.security_measures.title')}</strong></h2>
        <p>{t('legal_notice.security_measures.text')}</p>
      </section>
      </div>
    </div>
  );
};

export default AvisoLegal;
