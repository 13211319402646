import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the styles

const Tripulantes = () => {
  const { t, i18n } = useTranslation();
 
  return (
    <div 
      className="flex min-h-[480px] flex-col items-center justify-normal p-4 gap-6 bg-cover bg-center bg-no-repeat mt-40" 
    >
      <h1 className="text-primary text-4xl font-black text-center drop-shadow-custom-1 shadow-black">
      {t('content.crewtitle')}
      </h1>
      <div className="max-w-xl mx-auto rounded-lg shadow-lg border border-[#f4f3f0] bg-white p-3"> 
        <a href="/MODELO_CV_CORRECTO.docx">{t('content.crewtextcv')}</a>
        <hr/>
        <Link to="/tripulantecontact">{t('content.crewinsert')}</Link>
      </div>
      <div className="w-full max-w-screen-lg mt-6 flex justify-center">
        <Carousel 
          showThumbs={false} 
          autoPlay 
          infiniteLoop 
          showStatus={false} 
          interval={3000} 
          useKeyboardArrows 
          className="presentation-mode w-full max-w-2xl"
        >
          <div className="flex flex-col md:flex-row items-center rounded-lg shadow-lg border border-[#f4f3f0] bg-white">
            <img src="/img/Carrusel/marinos1.png" alt="Image 1" className="w-1/3 h-[200px] md:h-[250px] object-cover" />
          </div>
          <div className="flex flex-col md:flex-row items-center rounded-lg shadow-lg border border-[#f4f3f0] bg-white">
            <img src="/img/Carrusel/marinos3.png" alt="Image 2" className="w-1/3 h-[200px] md:h-[250px] object-cover" />
          </div>
          <div className="flex flex-col md:flex-row items-center rounded-lg shadow-lg border border-[#f4f3f0] bg-white">
            <img src="/img/Carrusel/marinos2.png" alt="Image 3" className="w-1/3 h-[200px] md:h-[250px] object-cover" />
          </div>
        </Carousel>
        <div className="fixed-text w-full md:w-1/2 p-4 mt-4">
          <h2 className="text-primary font-black text-ellipsis drop-shadow-custom-1 shadow-black">{t('content.crewtext')}</h2>
        </div>
      </div>
    </div>
  );
};

export default Tripulantes;
