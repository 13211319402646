import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Axios } from "./CompanyContactForm";
import { useTranslation } from 'react-i18next';
import { RotatingLines } from 'react-loader-spinner';

const TripulanteContactForm = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const { t, i18n } = useTranslation();
    const [cargos, setCargos] = useState([]);
    const [paises, setPaises] = useState([]);

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        telefono: '',
        cargo: '',
        pais: '',
        solicitud: '',
        idtiposolicitud: '',
        idestadosolicitud: '',
    });

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
    };

    const [file, setFile] = useState(null);

    useEffect(() => {
        const fetchCargos = async () => {
            try {
                const response = await Axios.get('/sca/getCargo', {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    },
                });
                //console.log('Response:', response.data.data);
                if (response.data.success) {
                    setCargos(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching cargos:', error);
            }
        };

        const fetchPais = async () => {
            try {
                const response = await Axios.get('/sca/getPais', {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    },
                });
                //console.log('Response:', response.data.data);
                if (response.data.success) {
                    setPaises(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching cargos:', error);
            }
        };

        fetchCargos();
        fetchPais();
    }, []);

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) newErrors.name = t('validation.name');
        if (!formData.email) {
            newErrors.email = t('validation.mail');
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = t('validation.phoneformat');
        }
        if (!formData.telefono) {
            newErrors.telefono = t('validation.phone');
        }
        // else if (!/^\d{10}$/.test(formData.telefono)) {
        //     newErrors.telefono = t('validation.phoneformat');
        // }
        if (!formData.solicitud) newErrors.solicitud = t('validation.request');
        if (!file) newErrors.file = t('validation.file');

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) return;

        setLoading(true);

        const form = new FormData();
        form.append('fullname', formData.name);
        form.append('email', formData.email);
        form.append('phonenumber', formData.telefono);
        form.append('idcargo', formData.cargo);
        form.append('idpais', formData.pais);
        form.append('description', formData.solicitud);
        form.append('idtiposolicitud', 2);
        form.append('idestadosolicitud', 1);
        if (file) {
            form.append('file', file);
        }

        try {
            const response = await Axios.post('/sca/addSolicitud', form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                },
            });
            //console.log('Response:', response.data);
            if (response.data.success) {
                setLoading(false);
                setFormData({
                    name: '',
                    email: '',
                    telefono: '',
                    cargo: '',
                    pais: '',
                    solicitud: '',
                    idtiposolicitud: '',
                    idestadosolicitud: '',
                });
                setFile(null);
                navigate('/success');
            }else{
                navigate('/failure');
            }
            
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
            navigate('/failure');
        }
    };

    const getCargoValue = (cargo) => {
        if (i18n.language === 'es') {
            return cargo.cargo;
        } else if (i18n.language === 'en') {
            return cargo.charge;
        }
        return cargo.cargo; // Default to 'cargo' if language is not recognized
    };

    const getPaisValue = (pais) => {
        if (i18n.language === 'es') {
            return pais.denominacion;
        } else if (i18n.language === 'en') {
            return pais.country;
        }
        return pais.country; // Default to 'cargo' if language is not recognized
    };

    return (
        <div className="relative">
            {loading && (
                <div className="absolute inset-0 bg-white bg-opacity-75 flex justify-center items-center">
                    <RotatingLines
                        visible={true}
                        height="96"
                        width="96"
                        strokeColor="blue"
                        strokeWidth="5"
                        animationDuration="2.75"
                        ariaLabel="rotating-lines-loading"
                    />
                </div>
            )}
            <form onSubmit={handleSubmit} className="max-w-xl mx-auto p-8 bg-white rounded-lg shadow-lg mt-40">
                <h2 className="text-2xl font-bold mb-6 text-gray-900">{t('form.sailorstitle')}</h2>
                <div className="mb-4">
                    <label htmlFor="name" className="block text-gray-700">{t('form.name')}</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={`w-full p-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400 ${errors.name ? 'border-red-500' : ''}`}
                    />
                    {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                </div>
                <div className="mb-4">
                    <label htmlFor="email" className="block text-gray-700">{t('form.mail')}</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={`w-full p-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400 ${errors.email ? 'border-red-500' : ''}`}
                    />
                    {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                </div>
                <div className="mb-4">
                    <label htmlFor="telefono" className="block text-gray-700">{t('form.phone')}</label>
                    <input
                        type="number"
                        id="telefono"
                        name="telefono"
                        value={formData.telefono}
                        onChange={handleChange}
                        className={`w-full p-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400 ${errors.telefono ? 'border-red-500' : ''}`}
                    />
                    {errors.telefono && <p className="text-red-500 text-sm">{errors.telefono}</p>}
                </div>
                <div className="mb-4">
                    <label htmlFor="cargo" className="block text-gray-700">{t('form.cargo')}</label>
                    <select
                        id="cargo"
                        name="cargo"
                        value={formData.cargo}
                        onChange={handleChange}
                        className={`w-full p-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400 ${errors.cargo ? 'border-red-500' : ''}`}
                    >
                        <option value="">{t('form.selectCargo')}</option>
                        {cargos.map((cargo) => (
                            <option key={cargo.idcargo} value={cargo.idcargo}>
                                {getCargoValue(cargo)}
                            </option>
                        ))}
                    </select>
                    {errors.cargo && <p className="text-red-500 text-sm">{errors.cargo}</p>}
                </div>
                <div className="mb-4">
                    <label htmlFor="pais" className="block text-gray-700">{t('form.pais')}</label>
                    <select
                        id="pais"
                        name="pais"
                        value={formData.pais}
                        onChange={handleChange}
                        className={`w-full p-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400 ${errors.pais ? 'border-red-500' : ''}`}
                    >
                        <option value="">{t('form.selectPais')}</option>
                        {paises.map((pais) => (
                            <option key={pais.idpais} value={pais.idpais}>
                                {getPaisValue(pais)}
                            </option>
                        ))}
                    </select>
                    {errors.pais && <p className="text-red-500 text-sm">{errors.pais}</p>}
                </div>
                <div className="mb-4">
                    <label htmlFor="solicitud" className="block text-gray-700">{t('form.requestsailors')}</label>
                    <textarea
                        id="solicitud"
                        name="solicitud"
                        value={formData.solicitud}
                        onChange={handleChange}
                        className={`w-full p-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400 ${errors.solicitud ? 'border-red-500' : ''}`}
                    ></textarea>
                    {errors.solicitud && <p className="text-red-500 text-sm">{errors.solicitud}</p>}
                </div>
                <div className="mb-4">
                    <label htmlFor="file" className="block text-gray-700">{t('form.file')}</label>
                    <div className="flex items-center">
                        <input
                            type="file"
                            id="file"
                            name="file"
                            onChange={handleFileChange}
                            className={`w-full p-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400 ${errors.file ? 'border-red-500' : ''}`}
                        />
                        <a
                            href="/MODELO_CV_CORRECTO.docx" // Cambia esta ruta a la correcta
                            className="ml-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                            download
                        >
                            {t('form.downloadFile')}
                        </a>
                    </div>
                    {errors.file && <p className="text-red-500 text-sm">{errors.file}</p>}
                </div>
                <button type="submit" className="w-full py-2 bg-primary text-white font-semibold rounded-md hover:bg-indigo-700">
                    {loading ? t('form.sendind') : t('form.send')}
                </button>
            </form>
        </div>
    );
};

export default TripulanteContactForm;
