// Card.js
import React from 'react';

const Card = ({ icon, title, description }) => {
  const icons = {
    MagnifyingGlass: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
        <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z" />
      </svg>
    ),
    List: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
        <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM40,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
      </svg>
    ),
    Users: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
        <path d="M117.25,157.92a60,60,0,1,0-66.5,0A95.83,95.83,0,0,0,3.53,195.63a8,8,0,1,0,13.4,8.74,80,80,0,0,1,134.14,0,8,8,0,0,0,13.4-8.74A95.83,95.83,0,0,0,117.25,157.92ZM40,108a44,44,0,1,1,44,44A44.05,44.05,0,0,1,40,108Zm210.14,98.7a8,8,0,0,1-11.07-2.33A79.83,79.83,0,0,0,172,168a8,8,0,0,1,0-16,44,44,0,1,0-16.34-84.87,8,8,0,1,1-5.94-14.85,60,60,0,0,1,55.53,105.64,95.83,95.83,0,0,1,47.22,37.71A8,8,0,0,1,250.14,206.7Z" />
      </svg>
    ),
    Briefcase: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
        <path d="M216,56H176V48a24,24,0,0,0-24-24H104A24,24,0,0,0,80,48v8H40A24,24,0,0,0,16,80V208a24,24,0,0,0,24,24H216a24,24,0,0,0,24-24V80A24,24,0,0,0,216,56ZM96,48a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96ZM224,96H176v16a8,8,0,0,1-16,0V96H96v16a8,8,0,0,1-16,0V96H32V80a8,8,0,0,1,8-8H72v24a8,8,0,0,0,8,8h96a8,8,0,0,0,8-8V72h32a8,8,0,0,1,8,8Z" />
      </svg>
    ),
    Buildings: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
        <path d="M248,224H232V56a16,16,0,0,0-16-16H128V24a16,16,0,0,0-16-16H40A16,16,0,0,0,24,24V224H8a8,8,0,0,0,0,16H248a8,8,0,0,0,0-16ZM120,224H104V208h16Zm0-32H104V176h16Zm0-32H104V144h16Zm0-32H104V112h16Zm0-32H104V80h16ZM88,224H72V208H88Zm0-32H72V176H88Zm0-32H72V144H88Zm0-32H72V112H88Zm0-32H72V80H88Zm32,120H88V208h32Zm0-32H88V176h32Zm0-32H88V144h32Zm0-32H88V112h32ZM200,72H184V56h16Zm0,32H184V88h16Zm0,32H184V120h16Zm0,32H184V152h16Zm0,32H184V184h16Zm0,32H184V216h16ZM152,56h16V72H152Zm0,32h16V88H152Zm0,32h16V120H152Zm0,32h16V152H152Zm0,32h16V184H152Zm0,32h16V216H152Zm32,8H144V56a8,8,0,0,0-8-8H112V216h32V200h16v16Zm24-144H192V72h16Zm0,32H192V120h16Zm0,32H192V152h16Zm0,32H192V184h16Zm0,32H192V216h16Z" />
      </svg>
    ),
    Megaphone: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
        <path d="M248,104a24,24,0,0,0-32-22.62V56a32.09,32.09,0,0,0-32-32H160a32.09,32.09,0,0,0-32,32v12.25L41.53,94.13a56.09,56.09,0,0,0,2.65,110.84l22.27,22.26A55.82,55.82,0,0,0,112,240a56.42,56.42,0,0,0,29.75-8.39A32.11,32.11,0,0,0,192,232a31.74,31.74,0,0,0,12-2.39,32.15,32.15,0,0,0,20-30.09V174.62A24,24,0,0,0,248,152Zm-16,0a8,8,0,1,1-8,8A8,8,0,0,1,232,104ZM160,32h24a16,16,0,0,1,16,16v74.46L160,139.68Zm0,208a16,16,0,0,1-11.31-4.69L113.4,193.42a56.44,56.44,0,0,0,14.2-14.66L181.31,206.69A16,16,0,0,1,160,240Zm56-33.48a16,16,0,0,1-16,16H168a16,16,0,0,1-16-16v-38l48-29.54Z" />
      </svg>
    )
  };

  return (
    <div className="rounded-lg shadow-lg border border-[#f4f3f0] bg-white p-3">
      <div className="size-4 text-[#181511]">{icons[icon]}</div>
      <div className="pt-1 text-[15px] font-bold">{title}</div>
      <div className="pt-1 text-[14px] text-black">{description}</div>
      {/* <div className="pt-1 text-[14px] text-[#9e9a91] ">{description}</div> */}
    </div>
  );
}

export default Card;
