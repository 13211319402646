import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Servicios = () => {
  const { t, i18n } = useTranslation();

  return (
    <div 
      className="flex min-h-[480px] flex-col items-center justify-normal p-4 gap-6 bg-cover bg-center bg-no-repeat mt-40" 
      
    >
      <h1 className="text-primary text-4xl font-black text-center drop-shadow-custom-1 shadow-black">
      {t('content.servicestitle')}
      </h1>
      <div className="max-w-xl mx-auto rounded-lg shadow-lg border border-[#f4f3f0] bg-white p-3"> 
      <p dangerouslySetInnerHTML={{ __html: t('content.servicetext') }}></p>
      </div>
    </div>
  );
};

export default Servicios;
