import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../middleware/AuthContext';
import axios from 'axios';

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [headers, setHeaders] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    async function getToken() {
      try {
        const response = await Axios.post('/login', {
          username: process.env.REACT_APP_USER,
          password: process.env.REACT_APP_PASS
        });
        const aux = response.data.token;
        sessionStorage.setItem('token', response.data.token);
        setToken(aux);
        setHeaders({
          accept: '*/*',
          Authorization: 'Bearer ' + response.data.token,
          'Content-Type': 'application/json',
        });
      } catch (error) {
        setToken(null);
        setHeaders(null);
        sessionStorage.removeItem('token');
      }
    }
    getToken();
  }, []);

  const onFinish = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (email.trim() !== '' && password.trim() !== '') {
      try {
        const response = await Axios.post(
            'sca/login',
            {
              correo: email.trim(),
              contrasenna: password.trim()
            },
            {
              headers: headers
            }
        );
        setLoading(false);
        if (response.data.success) {
          const info_user = response.data.data[0];
          sessionStorage.setItem('idusuario', response.data.data[0].idusuario);
          sessionStorage.setItem('info_user', JSON.stringify(info_user));
          window.location = '/userrequests';
        } else {
          setMessage(response.data.message);
        }
      } catch (error) {
        setLoading(false);
        sessionStorage.removeItem('idusuario');
        sessionStorage.removeItem('info_user');
        setMessage('Error de conexión con el servidor');
      }
    } else {
      setLoading(false);
      setMessage('Debe escribir correo y contraseña');
    }
    setTimeout(() => setMessage(''), 2500);
  };

  return (
      <div className="bg-gray-900 text-white min-h-screen flex items-center justify-center">
        <form onSubmit={onFinish} className="bg-gray-800 p-8 rounded-lg shadow-md w-full max-w-sm">
          <h2 className="text-2xl font-bold mb-6">Welcome back!</h2>
          {message && <div className="mb-4 text-red-500">{message}</div>}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Email</label>
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-3 rounded-md bg-gray-700 text-white focus:outline-none"
                required
            />
          </div>
          <div className="mb-6">
            <label className="block text-sm font-medium mb-2">Password</label>
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-3 rounded-md bg-gray-700 text-white focus:outline-none"
                required
            />
          </div>
          <button
              type="submit"
              className="w-full p-3 bg-blue-600 rounded-md text-white font-semibold hover:bg-blue-700"
              disabled={loading}
          >
            {loading ? 'Logging in...' : 'Log in'}
          </button>
        </form>
      </div>
  );
};

export default Login;
