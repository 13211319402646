import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from '../components/Home';
import Header from '../components/Header';
import Footer from '../components/Footer';
import TripulanteContactForm from '../pages/TripulanteContactForm';
import CompanyContactForm from '../pages/CompanyContactForm';
import Servicios from '../pages/Servicios';
import Empresas from '../pages/Empresas';
import Tripulantes from '../pages/Tripulantes';
import QuienesSomos from '../pages/QuienesSomos';
import AvisoLegal from '../pages/AvisoLegal';
import PoliticaPrivacidad from '../pages/PoliticaPrivacidad';
import CookieBanner from '../components/CookieBanner';
import Login from '../pages/Login';
import UserRequests from '../pages/UserRequests';
import ProtectedRoute from '../middleware/ProtectedRoute';
import UserRequestsHeader from '../components/UserRequestsHeader';
import { AuthProvider } from '../middleware/AuthContext';
import CompanyServiceForm from '../pages/CompanyServiceForm';
import SuccessPage from '../pages/SuccessPage';
import FailurePage from '../pages/FailurePage';

const AppRouter = () => {
  return (
    <AuthProvider>
      <Router>
        <MainLayout />
      </Router>
    </AuthProvider>
  );
};

const MainLayout = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/loginsailorscrewagency';
  const isUserRequestsPage = location.pathname === '/userrequests';

  return (
    <div className="flex flex-col min-h-screen">
      {!isLoginPage && !isUserRequestsPage && <Header />}
      {isUserRequestsPage && <UserRequestsHeader />}
      <div className="flex-1">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tripulantecontact" element={<TripulanteContactForm />} />
          <Route path="/companycontact" element={<CompanyContactForm />} />
          <Route path="/companyservice" element={<CompanyServiceForm />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/failure" element={<FailurePage />} />
          <Route path="/servicios" element={<Servicios />} />
          <Route path="/empresas" element={<Empresas />} />
          <Route path="/tripulantes" element={<Tripulantes />} />
          <Route path="/quienessomos" element={<QuienesSomos />} />
          <Route path="/avisolegal" element={<AvisoLegal />} />
          <Route path="/politicaprivacidad" element={<PoliticaPrivacidad />} />
          <Route path="/loginsailorscrewagency" element={<Login />} />
          <Route path="/userrequests" element={<ProtectedRoute><UserRequests /></ProtectedRoute>} />
        </Routes>
      </div>
      {!isLoginPage && !isUserRequestsPage && <Footer />}
      {!isLoginPage && !isUserRequestsPage && <CookieBanner />}
    </div>
  );
};

export default AppRouter;
