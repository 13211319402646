import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [headers, setHeaders] = useState(null);

  useEffect(() => {
    async function getToken() {
      try {
        const response = await axios.post('/login', {
          username: process.env.REACT_APP_USER,
          password: process.env.REACT_APP_PASS
        });
        const aux = response.data.token;
        sessionStorage.setItem('token', aux);
        setToken(aux);
        const headerAux = {
          accept: '*/*',
          Authorization: `Bearer ${aux}`,
          'Content-Type': 'application/json',
        };
        setHeaders(headerAux);
        axios.defaults.headers.common['Authorization'] = `Bearer ${aux}`;
        setIsAuthenticated(true);
      } catch (error) {
        setToken(null);
        setHeaders(null);
        sessionStorage.removeItem('token');
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    }

    const storedToken = sessionStorage.getItem('token');
    if (storedToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
      setIsAuthenticated(true);
      setLoading(false);
    } else {
      getToken();
    }
  }, []);

  const login = (token) => {
    setIsAuthenticated(true);
    sessionStorage.setItem('token', token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  };

  const logout = () => {
    setIsAuthenticated(false);
    sessionStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
  };

  return (
      <AuthContext.Provider value={{ isAuthenticated, login, logout, loading }}>
        {children}
      </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
