// src/components/CookieBanner.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const CookieBanner = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const accepted = localStorage.getItem('cookiesAccepted') === 'true';
    setCookiesAccepted(accepted);
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setCookiesAccepted(true);
  };

  const handleDecline = () => {
    setCookiesAccepted(true);
  };

  if (cookiesAccepted) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 flex justify-between items-center">
      <p>
        {t('footer.cookie')}.{' '}
        <Link  to="/politicaprivacidad">{t('footer.more')}</Link>
      </p>
      <div>
        <button onClick={handleAccept} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2">{t('footer.acept')}</button>
        <button onClick={handleDecline} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">{t('footer.denig')}</button>
      </div>
    </div>
  );
};

export default CookieBanner;
