import React from 'react';
import { useTranslation } from 'react-i18next';

const FailurePage = () => {
    const { t, i18n } = useTranslation();
    
    return (
        <div className="flex justify-center items-center h-screen">
            <h1 className="text-primary text-4xl font-black text-center drop-shadow-custom-1 shadow-black">{t('form.failure')}</h1>
        </div>
    );
};

export default FailurePage;
