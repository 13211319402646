import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <footer className="bg-primary text-white py-8">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-start px-4">
        <div className="flex flex-col gap-4 md:flex-row md:gap-12">
          <div className="flex items-top mb-6 md:mb-0">
            <img src="/img/Imagen1.png" alt="Logo" className="h-24" onClick={() => navigate('/loginsailorscrewagency')} />
          </div>
          <div className="flex flex-col gap-4 mb-6 md:mb-0">
            <h2 className="text-lg font-bold pr-10">SAILORS CREWING AGENCY</h2>
            <div className="text-left text-sm mb-2">
              &copy; 2024 SAC. {t('footer.legal')}
            </div>
          </div>

          <div className="flex flex-col gap-4 mb-6 md:mb-0">
            <div>
              <h3 className="font-bold mb-2 uppercase">{t('footer.address')}</h3>
              <p>Calle Benicarló 19, Bajo Derecha, 12003 Castellón</p>
            </div>
            <div>
              <h3 className="font-bold mb-2 uppercase">{t('footer.horary')}</h3>
              <p>{t('footer.horarytime')}</p>
            </div>
          </div>

          <div className="flex flex-col gap-4 mb-6 md:mb-0">
            <div>
              <h3 className="font-bold mb-2 uppercase">{t('footer.contact')}</h3>
              <h4 className="font-bold mb-2 uppercase">{t('footer.phone')}</h4><p> +34 692 066 238</p>
            </div>
            <div>
              <h3 className="font-bold mb-2 uppercase">{t('footer.email')}</h3>
              <p>agency@sailorscrew.com</p>
            </div>
          </div>

          <div className="flex flex-col gap-4 mb-6 md:mb-0">
            <div>
              <Link to="/politicaprivacidad" className="font-bold mb-2 uppercase">{t('footer.polity')}</Link>
            </div>
            <div>
              <Link to="/avisolegal" className="font-bold mb-2 uppercase">{t('footer.aviso')}</Link>
            </div>

          </div>


        </div>
      </div>
    </footer>
  );
}

export default Footer;
