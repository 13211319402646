import React from 'react';
import { useTranslation } from 'react-i18next';

const SuccessPage = () => {
    const { t, i18n } = useTranslation();

    return (
        <div className="flex justify-center items-center h-screen">
            <h1 className="text-primary text-4xl font-black text-center drop-shadow-custom-1 shadow-black">{t('form.success')}</h1>
        </div>
    );
};

export default SuccessPage;
