import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {Puff, FidgetSpinner, RotatingLines} from 'react-loader-spinner';

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const CompanyServiceForm = () => {
    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
    };

    const [file, setFile] = useState(null);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        telefono: '',
        solicitud: '',
        idtiposolicitud: '',
        idestadosolicitud: '',
    });

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) newErrors.name = t('validation.name');
        if (!formData.email) {
            newErrors.email = t('validation.mail');
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = t('validation.phoneformat');
        }
        if (!formData.telefono) {
            newErrors.telefono = t('validation.phone');
        } 
        // else if (!/^\d{10}$/.test(formData.telefono)) {
        //     newErrors.telefono = t('validation.phoneformat');
        // }
        if (!formData.solicitud) newErrors.solicitud = t('validation.request');
        // if (!file) newErrors.file = t('validation.file');

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) return;

        setLoading(true);

        const form = new FormData();
        form.append('fullname', formData.name);
        form.append('email', formData.email);
        form.append('phonenumber', formData.telefono);
        form.append('description', formData.solicitud);
        form.append('idtiposolicitud', 1);
        form.append('idestadosolicitud', 1);
        if (file) {
            form.append('file', file);
        }

        try {
            const response = await Axios.post('/sca/addSolicitud', form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                },
            });
            //console.log('Response:', response.data);
            if (response.data.success) {
                setLoading(false);
                setFormData({
                    name: '',
                    email: '',
                    telefono: '',
                    solicitud: '',
                    idtiposolicitud: '',
                    idestadosolicitud: '',
                });
                setFile(null);
                navigate('/success');
            }else{
                navigate('/failure');
            }
            
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
            navigate('/failure');
        }
    };

    return (
        <div className="relative">
            {loading && (
                <div className="absolute inset-0 bg-white bg-opacity-75 flex justify-center items-center">
                    <RotatingLines
                        visible={true}
                        height="96"
                        width="96"
                        strokeColor="blue"
                        strokeWidth="5"
                        animationDuration="2.75"
                        ariaLabel="rotating-lines-loading"
                        //wrapperStyle={{class:"bg-primary"}}
                        //wrapperClass="bg-primary"
                    />
                </div>
            )}
            <form onSubmit={handleSubmit} className="max-w-xl mx-auto p-8 bg-white rounded-lg shadow-lg mt-40">
                <h2 className="text-2xl font-bold mb-6 text-gray-900">{t('content.companiesinsert')}</h2>
                <div className="mb-4">
                    <label htmlFor="name" className="block text-gray-700">{t('form.namecompanies')}</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={`w-full p-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400 ${errors.name ? 'border-red-500' : ''}`}
                    />
                    {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                </div>
                <div className="mb-4">
                    <label htmlFor="email" className="block text-gray-700">{t('form.mail')}</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={`w-full p-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400 ${errors.email ? 'border-red-500' : ''}`}
                    />
                    {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                </div>
                <div className="mb-4">
                    <label htmlFor="telefono" className="block text-gray-700">{t('form.phone')}</label>
                    <input
                        type="number"
                        id="telefono"
                        name="telefono"
                        value={formData.telefono}
                        onChange={handleChange}
                        className={`w-full p-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400 ${errors.telefono ? 'border-red-500' : ''}`}
                    />
                    {errors.telefono && <p className="text-red-500 text-sm">{errors.telefono}</p>}
                </div>
                <div className="mb-4">
                    <label htmlFor="solicitud" className="block text-gray-700">{t('form.requestservices')}</label>
                    <textarea
                        id="solicitud"
                        name="solicitud"
                        value={formData.solicitud}
                        onChange={handleChange}
                        className={`w-full p-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400 ${errors.solicitud ? 'border-red-500' : ''}`}
                    ></textarea>
                    {errors.solicitud && <p className="text-red-500 text-sm">{errors.solicitud}</p>}
                </div>
                <div className="mb-4">
                    <label htmlFor="file" className="block text-gray-700">{t('form.file')}</label>
                    <input
                        type="file" 
                        id="file"
                        name="file"
                        onChange={handleFileChange}
                        className={`w-full p-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400 ${errors.file ? 'border-red-500' : ''}`}
                    />
                    {errors.file && <p className="text-red-500 text-sm">{errors.file}</p>}
                </div>
                <button type="submit" className="w-full py-2 bg-primary text-white font-semibold rounded-md hover:bg-indigo-700">
                    {loading ? t('form.sendind') : t('form.send')}
                </button>
            </form>
        </div>
    );
};

export default CompanyServiceForm;
