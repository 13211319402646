// src/components/Home.js
import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from './Hero';
import Section from './Section';

function Home() {
  return (
    <div className="flex flex-col items-center bg-white overflow-x-hidden" style={{ fontFamily: '"Plus Jakarta Sans", "Noto Sans", sans-serif' }}>
      <Helmet>
        <title>Sailors Crewing Agency</title>
        <meta name="description" content="Welcome to the Sailors Crewing Agency homepage." />
        <meta property="og:title" content="Sailors Crewing Agency" />
        <meta property="og:description" content="Welcome to the Sailors Crewing Agency homepage." />
        <meta icon="/favicon.ico"/>
      </Helmet>
      <Hero />
      <Section />
    </div>
  );
}

export default Home;
