// Section.js
import React from 'react';
import Card from './Card';
import { useTranslation } from 'react-i18next';

const Section = () => {
  const { t, i18n } = useTranslation();
  const cards = [
    // { icon: 'MagnifyingGlass', title: 'Explore career paths', description: 'Discover the roles and responsibilities that come with a Navy job.' },
    // { icon: 'List', title: 'See what\'s included', description: 'From healthcare to housing, see what\'s included in your Navy benefits.' },
    { icon: 'Users', title: t('content.cartel1'), description: t('content.cartel1text') },
    { icon: 'Briefcase', title: t('content.cartel2'), description: t('content.cartel2text') },
    // { icon: 'Buildings', title: 'Understand our mission', description: 'From fighting terrorism to disaster relief, see how the Navy protects and serves.' },
    { icon: 'Megaphone', title: t('content.cartel3'), description: t('content.cartel3text') },
  ];

  return (
    <>
      <h2 className="text-primary text-[22px] font-bold px-4 pb-3 pt-5 drop-shadow-lg shadow-black">{t('content.hometext')}</h2>
      <div className="grid grid-cols gap-3 p-4">
        {cards.map((card, index) => (
          <Card key={index} icon={card.icon} title={card.title} description={card.description} />
        ))}
      </div>
    </>
  );
}

export default Section;
