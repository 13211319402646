import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

function Header({ onLanguageChange }) {
  const { t, i18n } = useTranslation();
  const [scrolled, setScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [language, setLanguage] = useState(i18n.language.split('-')[0]);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleLanguageChange = (lng) => {
      setLanguage(lng.split('-')[0]);
    };

    i18n.on('languageChanged', handleLanguageChange);

    // Set initial language correctly in the combo box
    setLanguage(i18n.language.split('-')[0]);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <header
      className={`fixed top-0 left-0 right-0 z-50 flex items-end justify-between 
        whitespace-nowrap border-b border-solid border-b-[#f4f3f0] px-5 md:px-10 py-2.5 transition-all duration-300 ${
          isMobileMenuOpen || scrolled ? 'h-20' : 'h-40'
        }`}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url('/img/Imagen2.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="flex items-end justify-between w-full">
        <div className="flex items-center gap-8 text-[#181511] h-5">
          <img
            src="/img/Imagen1.png"
            alt="Logo"
            className="h-15 w-full object-cover max-md:h-10 cursor-pointer items-center"
            onClick={() => navigate('/')}
          />
        </div>
        <div className="hidden md:flex items-center gap-8">
          <nav className="flex items-center gap-6">
            <Link className="text-white text-sm font-bold" to="/quienessomos">
              {t('header.about')}
            </Link>
            <Link className="text-white text-sm font-bold" to="/servicios">
              {t('header.services')}
            </Link>
            <Link className="text-white text-sm font-bold" to="/tripulantes">
              {t('header.crew')}
            </Link>
            <Link className="text-white text-sm font-bold" to="/empresas">
              {t('header.companies')}
            </Link>
          </nav>
          <button
            onClick={() => navigate('/tripulantecontact')}
            className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-full h-10 px-4 bg-[#e89830] text-[#181511] text-sm font-bold"
          >
            <span className="truncate">{t('header.join')}</span>
          </button>
          <select
            onChange={(e) => changeLanguage(e.target.value)}
            value={language}
            className="ml-4 p-2 border rounded-full h-10 bg-white text-[#181511]"
          >
            <option value="en">En</option>
            <option value="es">Es</option>
          </select>
        </div>
        <div className="flex md:hidden items-center relative z-60">
          <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
            <div className="space-y-1.5">
              <span className="block w-8 h-0.5 bg-white"></span>
              <span className="block w-8 h-0.5 bg-white"></span>
              <span className="block w-8 h-0.5 bg-white"></span>
            </div>
          </button>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="flex flex-col items-center bg-black bg-opacity-75 p-4 md:hidden fixed top-0 left-0 right-0 bottom-0 z-50 mt-20">
          <nav className="flex flex-col items-center gap-4">
            <Link
              className="text-white text-sm font-bold"
              to="/quienessomos"
              onClick={closeMobileMenu}
            >
              {t('header.about')}
            </Link>
            <Link
              className="text-white text-sm font-bold"
              to="/servicios"
              onClick={closeMobileMenu}
            >
              {t('header.services')}
            </Link>
            <Link
              className="text-white text-sm font-bold"
              to="/tripulantes"
              onClick={closeMobileMenu}
            >
              {t('header.crew')}
            </Link>
            <Link
              className="text-white text-sm font-bold"
              to="/empresas"
              onClick={closeMobileMenu}
            >
              {t('header.companies')}
            </Link>
          </nav>
          <button
            onClick={() => {
              navigate('/tripulantecontact');
              closeMobileMenu();
            }}
            className="mt-4 flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-full h-10 px-4 bg-[#e89830] text-[#181511] text-sm font-bold"
          >
            <span className="truncate">{t('header.join')}</span>
          </button>
          <select
            onChange={(e) => changeLanguage(e.target.value)}
            value={language}
            className="mt-4 p-2 border rounded-full h-10 bg-white text-[#181511]"
          >
            <option value="en">En</option>
            <option value="es">Es</option>
          </select>
        </div>
      )}
    </header>
  );
}

export default Header;
