import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../middleware/AuthContext';

const UserRequestsHeader = () => {
  const navigate = useNavigate();
  // const { logout } = useAuth();

    const usuario = JSON.parse(sessionStorage.getItem('info_user')).correo;

  const handleLogout = () => {
      sessionStorage.removeItem('idusuario');
      sessionStorage.removeItem('info_user');
      window.location='/'
  };

  return (
    <header className="bg-gray-800 text-white py-4 px-4 md:px-8 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
      <div className="flex items-center space-x-4">
        <img src="/img/Imagen1.png" alt="Logo" className="h-8" />
        <h1 className="text-lg md:text-xl font-bold">SAILORS CREWING AGENCY</h1>
      </div>
      <div className="flex items-center space-x-4">
          <h1 className="text-lg md:text-xl font-bold">{usuario}</h1>
            <button onClick={handleLogout} className="bg-red-600 px-4 py-2 rounded-md text-white font-semibold hover:bg-red-700">
              Logout
            </button>
      </div>
    </header>
  );
};

export default UserRequestsHeader;
